import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const cta = ({ href }) => (
  <a href={href} alt="Expert fissures Rédis Contonnec">
    <StaticImage
      className="rounded-lg shadow-lg"
      src="../../../images/cta/CTA Regis COTONNEC.jpg"
      alt="Régis Cotonnec"
    />
  </a>
);

export default cta;
