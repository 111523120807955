import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const cta = ({ href }) => (
  <a href={href} alt="Expert fissures Ridha Boukhili">
    <StaticImage
      className="rounded-lg shadow-lg"
      src="../../../images/cta/CTA Ridha BOUKHILI.jpg"
      alt="Ridha Boukhili"
    />
  </a>
)

export default cta
