import React from "react";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { MDXProvider } from "@mdx-js/react";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { StaticImage } from "gatsby-plugin-image";

import { encode } from "js-base64";

import Header from "../../components/headers/header";
import Ariane from "../../components/ariane";
import Footer from "../../components/footers/footer";
import FormExpert from "../../components/forms/formExpert";

import Reassurance from "../../components/reassurance";

////Intégration Shortcodes
import CTAExpertPresChezVous from "../../components/shortcodes/CTAExpertPresChezVous";
import CTAExpertAvisIndependant from "../../components/shortcodes/CTAExpertAvisIndependant";
import Image from "../../components/shortcodes/Image";
import Youtube from "../../components/shortcodes/youtube";

// CTA Expert
import CTAOpengroupe from "../../components/shortcodes/cta/CTAOpengroupe";
import CTAEdieuxExpertise from "../../components/shortcodes/cta/CTAEdieuxExpertise";
import CTADuboisExpertise from "../../components/shortcodes/cta/CTADuboisExpertise";
import CTACabinetE2PPloixExpertise from "../../components/shortcodes/cta/CTACabinetE2PPloixExpertise";
import CTALunoaExpertConseilBenamar from "../../components/shortcodes/cta/CTALunoaExpertConseilBenamar";
import CTAAvayahJeromeGallaud from "../../components/shortcodes/cta/CTAAvayahJeromeGallaud";
import CTACabinetLespagnol from "../../components/shortcodes/cta/CTACabinetLespagnol";
import CTAEGExpabatRoux from "../../components/shortcodes/cta/CTAEGExpabatRoux";
import CTAVincentMoreau from "../../components/shortcodes/cta/CTAVincentMoreau";
import CTABatExpert34 from "../../components/shortcodes/cta/CTABatExpert34";
import CTABenoitToussaint from "../../components/shortcodes/cta/CTABenoitToussaint";
import CTA1GSolutions from "../../components/shortcodes/cta/CTA1GSolutions";
import CTA2RExpertise from "../../components/shortcodes/cta/CTA2RExpertise";
import CTARegisCotonnec from "../../components/shortcodes/cta/CTARegisCotonnec";

const shortcodes = {
  Youtube,
  Image,
  CTAOpengroupe,
  CTAExpertPresChezVous,
  CTAExpertAvisIndependant,
  CTAEdieuxExpertise,
  CTADuboisExpertise,
  CTACabinetE2PPloixExpertise,
  CTALunoaExpertConseilBenamar,
  CTAAvayahJeromeGallaud,
  CTACabinetLespagnol,
  CTAEGExpabatRoux,
  CTAVincentMoreau,
  CTABatExpert34,
  CTABenoitToussaint,
  CTA1GSolutions,
  CTA2RExpertise,
  CTARegisCotonnec,
};
////

const MdxPage = ({
  data: {
    mdx: {
      embeddedImagesRemote,
      frontmatter: {
        date,
        slug,
        title,
        title_meta,
        description_meta,
        excerpt,
        thumbnailText,
        embeddedImagesLocal,
        fil_ariane,
      },
      body,
    },
  },
}) => {
  const encryptedMail = encode("agence@expert-batiment-47.fr");
  const moyen = "Formulaire Fissuration Geo Regis-Cotonnec";

  return (
    <div className="bg-white">
      <Helmet>
        <title>{title_meta}</title>
        <meta name="description" content={description_meta} />
      </Helmet>
      <header className="sticky top-0 z-50 bg-white">
        <Header />
      </header>
      <div class="relative mx-auto mb-4 max-w-7xl shadow-lg lg:flex lg:shadow-none">
        <Ariane fil_ariane={fil_ariane} />
      </div>

      <div class="relative mx-auto max-w-7xl h-full lg:flex">
        <main className="">
          <div class="flex overflow-hidden flex-1">
            <div class="flex flex-col flex-1">
              <div class="flex overflow-y-auto flex-1 bg-white paragraph">
                <div className="overflow-hidden relative py-4 bg-white">
                  <div className="relative px-4 sm:px-6 lg:px-8">
                    <div className="mx-auto max-w-prose text-lg">
                      <h1>
                        <span className="block mt-2 text-3xl font-extrabold tracking-tight leading-8 text-center text-gray-900 sm:text-4xl">
                          {title}
                        </span>
                      </h1>

                      <GatsbyImage
                        className="rounded-lg shadow-lg"
                        alt={thumbnailText}
                        image={getImage(embeddedImagesLocal[0])}
                      />
                    </div>

                    <div className="mx-auto mt-6 text-gray-500 prose prose-blue prose-lg">
                      <MDXProvider components={shortcodes}>
                        <MDXRenderer
                          className="mx-auto mt-6 text-gray-500 prose prose-sky prose-lg"
                          remoteImages={embeddedImagesRemote}
                          localImages={embeddedImagesLocal}
                        >
                          {body}
                        </MDXRenderer>
                      </MDXProvider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>

        <aside class="col-span-1 self-start p-4 lg:py-8 lg:w-1/3">
          <div className="bg-white">
            <div className="flex flex-col flex-grow py-4 px-4 mb-8 text-center bg-white rounded-lg border shadow-lg sm:py-8 sm:px-6 lg:px-8">
              <div className="mb-4 text-xl font-bold text-center">
                <p>Votre Expert Bâtiment proche de chez vous</p>
              </div>
              <a href="/expert-fissures/regis-cotonnec/">
                <div className="mx-auto w-40 h-40 rounded-full">
                  <StaticImage
                    className="mx-auto w-40 h-40 rounded-full"
                    src="../../markdown-pages/experts/regis-cotonnec/regis-cotonnec.png"
                    alt=""
                  />
                </div>
                <h3 className="mt-4 text-2xl font-bold tracking-tight text-gray-900">
                  Cabinet d'Expertise Amiable
                </h3>
                <p className="mt-2 text-base leading-7 text-gray-600">
                  <strong>Régis COTONNEC</strong>
                  <br />
                  Rue Jacques Aussel
                  <br />
                  47290 Castelnaud-de-Gratecambe
                  <br />
                  06 74 98 23 95
                </p>
              </a>

              <FormExpert encryptedMail={encryptedMail} moyen={moyen} />
            </div>
          </div>

          <Reassurance />
        </aside>
      </div>
      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export const query = graphql`
  query ($slug: String!) {
    mdx(frontmatter: { slug: { eq: $slug } }) {
      embeddedImagesRemote {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      frontmatter {
        date
        slug
        fil_ariane
        title
        title_meta
        description_meta
        excerpt
        thumbnailText
        embeddedImagesLocal {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
      body
    }
  }
`;

export default MdxPage;
